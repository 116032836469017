/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, finalize } from 'rxjs';
import { LoaderService } from '../service/loader.service';
import { SiteAPIPath, TEMPCOMMONVALUE, URLNOTNEEDEDLOADER } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class LoaderInterceptor implements HttpInterceptor {

  constructor(private _loaderService: LoaderService) { }
  private requests: HttpRequest<any>[] = [];

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const currentUrl = req.url;

    // let matchParamUrl = req.url.split(/(\d+)/).;
    let isURLAddToLoad = (URLNOTNEEDEDLOADER.indexOf(currentUrl) !== -1) || currentUrl.includes('thumbnails/development')
    if (isURLAddToLoad) {
      const split_string: any = req.url.split(/(\d+)/).pop();
      isURLAddToLoad = (URLNOTNEEDEDLOADER.indexOf(split_string) !== -1) || currentUrl.includes('thumbnails/development')
      if (isURLAddToLoad) {
        const split_string: any = req.url.split('=');
        if (split_string && split_string.length > 0) {
          isURLAddToLoad = (URLNOTNEEDEDLOADER.indexOf(split_string[0]) !== -1) || currentUrl.includes('thumbnails/development')
        }
      }
    }


    if (currentUrl.includes(TEMPCOMMONVALUE.FLOORUPLOADFILE)) {
      isURLAddToLoad = false;
    }
    const reuestContainsType: any = '?Content-Type=multipart';
    // Create an array of GET method URLs to hide loading spinner for.
    if (!isURLAddToLoad || (req.method === 'GET' && req.url.indexOf(`${SiteAPIPath.site}/floorthumbnails`) < 0) && !currentUrl.includes('thumbnails/development')) {
      this.addRequest(req);
    } else if (isURLAddToLoad && req.method === 'DELETE' && req.url.indexOf(`${SiteAPIPath.site}`) > -1) {
      this.addRequest(req);
    } else if (req.url.includes('.png' + reuestContainsType) || req.url.includes('.PNG' + reuestContainsType) || req.url.includes('.jpeg' + reuestContainsType) || req.url.includes('.jpg' + reuestContainsType) || req.url.includes('.JPEG' + reuestContainsType) || req.url.includes('.JPG' + reuestContainsType)) {
      this.addRequest(req);
    }
    return new Observable((observer: any) => {
      const subscription = next.handle(req)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(req);
              observer.next(event);
            }
          },
          err => { this.removeRequest(req); observer.error(err); },
          () => { this.removeRequest(req); observer.complete(); });
      // teardown logic in case of cancelled requests
      return () => {
        this.removeRequest(req);
        if (subscription && subscription !== undefined) {
          subscription.unsubscribe();
        }
      };
    });
  }
  addRequest(req: any) {
    this.requests.push(req);
    this._loaderService.isLoading.next(true);
  }
  removeRequest(req: HttpRequest<any>) {
    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);

    }
    // console.log(i, this.requests.length);
    this._loaderService.isLoading.next(this.requests.length > 0);
  }
}
