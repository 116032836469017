import { Environment } from "@SiteOwl/core";

class EnvironmentImpl implements Environment {
  production = true;
  apiPath = './siteowl/';
  // apiPath = 'http://dev1.site-owl.com:38622/siteowl/';
  localstack = false;  // make it true for connecting aws localstack
  NX_NO_CLOUD = true
  cdnUrl = 'https://live.site-owl.com/';
  mixPanelToken = 'ee15ac1e5ad7f1ff38ccd282a0931e01';
  reportApiPath = 'https://live-reports.site-owl.com/siteowl/';
  envName = 'live';
  fronteggBaseUrl =  'https://auth.site-owl.com';
  fronteggClientId = 'ccf049ac-beb5-4e56-be2a-d6e1e355330a';
  sentryDSN = 'https://30fd8d775bc77fabf439306e8550cd97@o4506073223593984.ingest.us.sentry.io/4507168275234816';
  fronteggLogin = true
}

export const environment = new EnvironmentImpl();
