import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { NxWelcomeComponent } from './nx-welcome.component';
import { AppRouterModule } from './app.routes';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Environment, LoaderInterceptor } from '@SiteOwl/core';
import { environment } from '../environments/environment';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SecureComponent } from './secure/secure.component';
import { UiModule } from '@SiteOwl/UI';
import { FronteggAppOptions } from '@frontegg/types';
import { FronteggAppModule } from '@frontegg/angular';
import { FronteggSuccessComponent } from './frontegg-success/frontegg-success.component';
import * as Sentry from "@sentry/angular-ivy";
import { Router } from '@angular/router';

const fronteggConfig: FronteggAppOptions = {
  contextOptions: {
    baseUrl: environment.fronteggBaseUrl,
    clientId: environment.fronteggClientId,
  },
  authOptions: {
    hostedLoginOptions: {
      loadUserOnFirstLoad: environment.fronteggLogin,
    },
    keepSessionAlive: environment.fronteggLogin // Uncomment this in order to maintain the session alive
  },
  hostedLoginBox: true,
};

@NgModule({
  declarations: [
    AppComponent,
    NxWelcomeComponent,
    SecureComponent,
    FronteggSuccessComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRouterModule,
    HttpClientModule,
    ToastrModule.forRoot({
      preventDuplicates: true
    }),
    UiModule,
    FronteggAppModule.forRoot(fronteggConfig),
  ],
  providers: [
    { provide: Environment, useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
