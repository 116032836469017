<so-header-bar></so-header-bar>
<so-left-bar></so-left-bar>
<!-- eslint-disable @angular-eslint/template/no-negated-async -->
<div *ngIf="isLoadingDone && !(loaderService.isLoading | async) && !(floorLoadService.isFloorLoading | async)">
    <so-loader [mainpageloader]='true'></so-loader>
</div>
<!-- <error-page *ngIf="isError;else ifErrorNot" [errorData]="errorData"></error-page> -->
<!-- <ng-template #ifErrorNot> -->
    <div class="content-wrapper">
        <router-outlet></router-outlet>
    </div> 
<!-- </ng-template> -->