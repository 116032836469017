import { Injectable, inject } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  ResolveFn
} from '@angular/router';
import { Observable, of } from 'rxjs';

export const AccountResolve: ResolveFn<any> = (route: ActivatedRouteSnapshot) => {
  return inject(SecureResolver).resolve(route);
};

@Injectable({
  providedIn: 'root'
})
export class SecureResolver {
  constructor(private router: Router) { }
  resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
    let path: any = ""
    if (route.url && route.url[1] !== undefined && route.url[1] !== null) {
      path = route.url[1].path;
    } else if (route.url && route.url[0] !== undefined && route.url[0] !== null) {
      path = route.url[0].path;
    }
    if (path === 'customerdevice') {
      this.router.navigate(['/secure/live-site/devices'], { queryParams: route.queryParams });
    } else if (path === 'projectdevice') {
      this.router.navigate(['/secure/project/devices'], { queryParams: route.queryParams });
    } else if (path === 'customer-settings') {
      this.router.navigate(['/secure/settings/customer-settings']);
    }
    return of(true);
  }
}
