import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'so-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'site-owl';
  returnUrl!: string;
  dashboardTabSelection!: string;
  constructor(private router: Router, private route: ActivatedRoute  ) {
  }
}
