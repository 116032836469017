import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AuthGuard, CanLoadGuard, PublicAuthGuard, SecureResolver } from '@SiteOwl/core';
import { SecureComponent } from './secure/secure.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { ErrorPageComponent, InvalidPageComponent } from '@SiteOwl/error-pages';
import { FronteggSuccessComponent } from './frontegg-success/frontegg-success.component';

export const appRoutes: Route[] = [
    {path:'frontegg-success', component: FronteggSuccessComponent},
    {path:'oauth/callback', component: FronteggSuccessComponent},
    {path:'oauth/auth-flows', component: FronteggSuccessComponent},
    { path: '', redirectTo: 'public/login', pathMatch: 'full' },
    { path: 'public', canActivate: [PublicAuthGuard], loadChildren: () => import('@SiteOwl/public').then(m => m.PublicModule) },
    {
        path: 'secure', component: SecureComponent, canActivate: [AuthGuard], children: [
            { path: '', canLoad: [CanLoadGuard], loadChildren: () => import('@SiteOwl/floor-plan').then(m => m.ModulesFloorPlanModule) },
            { path: 'settings', canLoad: [CanLoadGuard], loadChildren: () => import('@SiteOwl/settings').then(m => m.ModulesSettingsModule) },
            { path: 'live-site', canLoad: [CanLoadGuard], loadChildren: () => import('@SiteOwl/live-site').then(m => m.ModulesLiveSiteModule) },
            { path: 'global-manage', canLoad: [CanLoadGuard], loadChildren: () => import('@SiteOwl/global-manage').then(m => m.GlobalManageModule) },
            { path: 'dashboard', canLoad: [CanLoadGuard], loadChildren: () => import('@SiteOwl/dashboard').then(m => m.ModulesDashboardModule) },
            { path: 'project', canLoad: [CanLoadGuard], loadChildren: () => import('@SiteOwl/project').then(m => m.ModulesProjectModule) },
            { path: 'profile',  loadChildren: () => import('@SiteOwl/profile').then(m => m.ModulesProfileModule) },
            { path: 'user/profile',  loadChildren: () => import('@SiteOwl/profile').then(m => m.ModulesProfileModule) },
            { path: 'customer/ticket', canLoad: [CanLoadGuard], loadChildren: () => import('@SiteOwl/ticket').then(m => m.ModulesTicketModule) },
            { path: 'audit', canLoad: [CanLoadGuard], loadChildren: () => import('@SiteOwl/audit').then(m => m.ModulesAuditModule) },
            { path: 'site/planning', canLoad: [CanLoadGuard], loadChildren: () => import('@SiteOwl/live-Planning').then(m => m.ModulesLivePlanningModule) },
            { path: 'provider', canLoad: [CanLoadGuard], loadChildren: () => import('@SiteOwl/provider').then(m => m.ModulesProviderModule) },
            { path: 'error',  loadChildren: () => import('@SiteOwl/error-pages').then(m => m.ErrorPagesModule) },
            { path: 'invalid-page',  component: InvalidPageComponent },
            {
                path: '**', component: ErrorPageComponent, resolve: { error: SecureResolver }
            },

            { path: 'site', loadChildren: () => import('@SiteOwl/live-site').then(m => m.ModulesLiveSiteModule) },

        ]
    },
    
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule]
})
export class AppRouterModule { }